const NAMES = {
  ACTIVITIES_IN_COLLEGE: 'activities_in_college_v2',
  ADDRESS: 'address_v2',
  APP: 'Encourage for Students',
  APP_VERSION_STORAGE_KEY: 'app_version',
  BACKGROUND: 'background_v2',
  CAMPUS_POPULATION: 'campus_population_v1',
  COLLEGE_SURVEY: 'college_match_v3',
  COLLEGE_TYPES: 'college_types_v2',
  COLLEGE_TYPES_ELEMENT_NAME: 'college_types_v2', // MIGHT BE THE SAME AS COLLEGE_TYPES but might be different too
  COMPANY: 'Encoura, LLC',
  DISTANCE_FROM_HOME: 'distance_from_home_v1',
  FULL_SURVEY: 'full_survey_v2',
  INTEREST_INVENTORY_SURVEY: 'interest_inventory_v1',
  INTEREST_INVENTORY_VERSION: 'INTEREST_V1', // Not actually used as a variable
  MINDSET_CARE: 'mindset_care_about_v1',
  MINDSET_EXPECTATIONS_FOR_EDUCATORS: 'mindset_expectations_for_education_v1',
  MINDSET_EXPERIENCES_LEARN_FROM: 'mindset_experiences_learn_from_v1',
  MY_GOAL_CAREER: 'my_goal_career_v2',
  MY_GOAL_MAJOR: 'my_goal_major_v1',
  ONBOARDING_SURVEY: 'onboarding_v6',
  PROFILE_GPA: 'profile_gpa_v2',
  PROFILE_GRADYEAR_HIGHSCHOOL: 'profile_gradyear_highschool_v2',
  PROFILE_HSCOARSEWORK: 'profile_hscoarsework_v1',
  PROFILE_MILITARY: 'profile_military_v2',
  PSAT_SURVEY: 'profile_psat_scores_v1',
  RECRUITMENT: 'recruitment_v1',
  RECRUITMENT_SURVEY: 'recruitment_v2',
  RELIGIOUS_COLLEGE: 'religious_college_v1',
  SAT_SURVEY: 'profile_sat_scores_v1',
  SCHOLARSHIP_SURVEY: 'scholarship_match_v4',
  SIZE_OF_COLLEGE: 'size_of_college_v1',
  SOCIAL_BEHAVIOR: 'social_behavior_v1',
  TOP_STATES: 'top_states_v2',
  UNIQUE_COLLEGE: 'unique_college_v2',
};

export default NAMES;
